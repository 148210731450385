import React, {useEffect, useState} from "react"
import  "../components/layout"
import { Link } from "gatsby"
import GoogleMapReact from 'google-map-react';
import { render } from "react-dom";


const AnyReactComponent = ({ lat,lng,text,custom }) => {
return (<a href={custom?custom:`https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${lat},${lng}`}> <div  className={`wMarker icon solid fa-${text}`}></div></a>);
}

function ReturnRSVP(props){
  
  var [xrsvp,setVal] = useState({rsp:"0",ext:"-1"});
  let [update,setUpdate] = useState(0);
  
  useEffect(()=>{
    fetch(`https://europe-central2-stately-block-596.cloudfunctions.net/function-1?update=${props.name}`).then(res=>{
return res.json();
}).then(json=>{
  //console.log('here')
  var f = {rsp:json.Attending,ext:json.Plus}
  setVal(f);
})

/*var fos = {rsp:0,ext:1}
setVal(fos);*/
  },[])

  useEffect(()=>{

    if((update*1)>0 && update<8){
      fetch(`https://europe-central2-stately-block-596.cloudfunctions.net/function-1?update=${props.name}&answ=${xrsvp.rsp+";"+xrsvp.ext}`).then(e=>{
        setUpdate(8)
        setTimeout(()=>{
          setUpdate(0);
        },3000)
      });
    }

  },[update])

let triggerUpdate = (e,u) =>{
  //console.log('new no change?')
  //console.log(u)
  setVal((prevstate)=>({...prevstate,...e}))
  setUpdate(u);
  }

  return(
  <div>
    {props ? 
      (<span> Ar dalyvausi/dalyvausite?<br></br>
      <div className={update==8 ? "ssaved" : "ssaved-out"}> išsaugota</div>
        <div>
          <input type='radio' id='rsyes' name='rsvp' value='1' checked={xrsvp.rsp=="1" ? true:false}  onChange={(e)=>triggerUpdate({rsp:e.target.value},1)}/>
          
          <label htmlFor='rsyes'>Taip</label>
        </div>
        <div>
          <input type='radio' id='rsno' name='rsvp' value='2'checked={xrsvp.rsp=="2" ? true:false} onChange={(e)=>triggerUpdate({rsp:e.target.value},2)}/>
          <label htmlFor='rsno'>Nepavyks...</label>
        </div>
        {/*Plus one logic */}
        {(xrsvp.ext*1)>-1 && xrsvp.rsp=="1" ? (<span>
          +1?
          <div>
          <input type='radio' id='exyes' name='ext' value='1' checked={xrsvp.ext=="1" ? true:false}  onChange={(e)=>triggerUpdate({ext:e.target.value},3)}/>
          
          <label htmlFor='exyes'>Taip</label>
        </div>
        <div>
          <input type='radio' id='exno' name='ext' value='2'checked={xrsvp.ext=="2" ? true:false} onChange={(e)=>triggerUpdate({ext:e.target.value},4)}/>
          <label htmlFor='exno'>Ne</label>
        </div>
        </span>) : ""}
      </span>
      
      )
      
      : ""}

  </div>
  )
    
}

export default function Home({ pageContext: { Guest }}) {
  return (<div>
<section id="header">
  <header>
    <span className="image avatar"><img src="/images/avatar.jpg" alt="" /></span>
    <h1 id="logo"><a href="#">Jurgita ir Artūras 2021-08-13</a></h1>
    </header>
  <nav id="nav">
    <ul>
     {Guest? <li><a href="#zero" className="active">MES ŽENYJAMĖS!</a></li> : ""}
      <li><a href="#one" className={Guest? "":"active"} >CEREMONIJA</a></li>
      <li><a href="#two">PUTOJANTIS VYNAS IR BUČINIAI</a></li>
      <li><a href="#three">VAKARĖLIS</a></li>
      {Guest? <li><a href="#four">RSVP</a></li> : ""}
      
    </ul>
    
  </nav>
  {/*<footer>
    <ul className="icons">
      <li><a href="#" className="icon brands fa-twitter"><span className="label">Twitter</span></a></li>
      <li><a href="#" className="icon brands fa-facebook-f"><span className="label">Facebook</span></a></li>
      <li><a href="#" className="icon brands fa-instagram"><span className="label">Instagram</span></a></li>
      <li><a href="#" className="icon brands fa-github"><span className="label">Github</span></a></li>
      <li><a href="#" className="icon solid fa-envelope"><span className="label">Email</span></a></li>
    </ul>
  </footer>*/}
</section>


<div id="wrapper">


    <div id="main">

       
        <section id="zero">
          <div className="image main" data-position="center">
            <img src="/images/banner.jpg" alt="" />
          </div>
         {Guest ?  <div className="container">
            <header className="major">
              <h3>MES ŽENYJAMĖS!</h3>
              <b>{Guest.salutation}</b>
              <p>
              Kviečiame būti mūsų meilės šventės dalyviais, kurią įsivaizduojame mums brangių žmonių apsupty.
              Šventė vyks rugpjūčio 13 d. Vilniuje. Visa detali šventės eiga pateikta žemiau.
              Būtume dėkingi jei iki liepos 18 d. pažymėtumėte savo dalyvavimą <a href="#four">(puslapio apačioje)</a>.
              </p>
              

              
            </header>
        </div> : ""}
        </section>

        <section id="one">
          <div className="container">

              <h3>CEREMONIJA</h3>
              <p>Oficialiausia šventės dalis. Jei ištarsime “taip” (intrigėlė) ir apsižieduosime- galėsime vadintis Tamulaičiais oficialiai.</p>

            <ul className="feature-icons">
              
              <li className="icon solid fa-clock"> 15:00 val.</li>
              <li className="icon solid fa-map-marker">Vilniaus Rotušė, Mero menė</li>
              <li className="icon solid fa-user-tie">Kaip į šventę</li>

            </ul>
            <div style={{width:"100%",height:"40vh"}}>            <GoogleMapReact defaultZoom={18} bootstrapURLKeys={{key:"AIzaSyA_JR3fWbowqmdji_6jWL1tBc3WfcIyAgY"}} defaultCenter={{lat: 54.6781920627391 ,lng:25.287068371618517}}>

<AnyReactComponent lat={54.678258539923775} lng={25.28689720935528} text="heart"></AnyReactComponent>
</GoogleMapReact></div>
          </div>
        </section>

        <section id="two">
          
          <div className="container">
          <h3>PUTOJANTIS VYNAS IR BUČINIAI</h3>
              <p>Laikas bučiniams, apsikabinimams, taurių susidaužimo skambesiui bei, žinoma, bendrai fotografijai.</p>
            <ul className="feature-icons">
              <li className="icon solid fa-clock">15:30 val. (po ceremonijos Rotušėje)</li>
              <li className="icon solid fa-map-marker">Botanist kiemelis, Didžioji g. 24</li>
              <li className="icon solid fa-gift">Vestuvių dovanėles prašome pakuoti į vokelį.</li>
              <li className="icon brands fa-pagelines">Gėles palikime žydėti pievose, o pasveikinimui mielai priimtume baltą helio balioną</li>
              
            </ul>
            <div style={{width:"100%",height:"40vh"}}>            <GoogleMapReact defaultZoom={18} bootstrapURLKeys={{key:"AIzaSyA_JR3fWbowqmdji_6jWL1tBc3WfcIyAgY"}} defaultCenter={{lat: 54.678485512221485, lng:25.287877108574794}}>

<AnyReactComponent lat={54.67847263830625} lng={25.287864020632053} text="glass-cheers" ></AnyReactComponent>
</GoogleMapReact></div>

          
          </div>
        </section>


        <section id="three">
          <div className="container">
          <h3>VAKARĖLIS</h3>
              <p>VA-KA-RĖ-LIS! Ypatinga proga- ypatingoje mums vietoje. Ši šventės dalis- be programos: bendrausime, šoksime ir kelsime taures svaiginančių gėrimų. Jei bus noras surengti šventinį pasirodymą- mielai prašome :)</p>
            <ul className="feature-icons">
              
              <li className="icon solid fa-clock">20:00 val.</li>
              <li className="icon solid fa-map-marker">Gringo pub, Vilniaus g. 31</li>
              <li className="icon solid fa-utensils">Užkandžiai ir open bar</li>
              <li className="icon solid fa-user-tie">Kaip į vestuves bare</li>

            </ul>
            <div style={{width:"100%",height:"40vh"}}>            <GoogleMapReact defaultZoom={18} bootstrapURLKeys={{key:"AIzaSyA_JR3fWbowqmdji_6jWL1tBc3WfcIyAgY"}} defaultCenter={{lat: 54.684168821994234 ,lng:25.279215173390334}}>

<AnyReactComponent lat={54.684168821994234} lng={25.279215173390334} text="beer" custom='https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=Gringo%20pub,Vilnius,01402?s=ppprst'></AnyReactComponent>
</GoogleMapReact></div>
          </div>
        </section>
        {Guest ?  (
        <section id='four'>
        <div className="container">
          <h3>RSVP</h3>
          <ReturnRSVP name={Guest.name}> </ReturnRSVP>
        </div>
      </section>
        ) : null}
      
        
      

    </div>


    <section id="footer">
      <div className="container">
        <ul className="copyright">
          <li>&copy; Untitled. All rights reserved.</li><li>Design: <a href="http://html5up.net">HTML5 UP</a></li>
        </ul>
      </div>
    </section>

</div>


<script src="/assets/js/jquery.min.js"></script>
<script src="/assets/js/jquery.scrollex.min.js"></script>
<script src="/assets/js/jquery.scrolly.min.js"></script>
<script src="/assets/js/browser.min.js"></script>
<script src="/assets/js/breakpoints.min.js"></script>
<script src="/assets/js/util.js"></script>
<script src="/assets/js/main.js"></script>

</div>)

}

